import { Component } from '@angular/core';
import { AnalyticsService, PersistenceService, UserService } from '@app/core/services';
import { AnalyticsEventEnum } from '@app/core/services/analytics/analytics-event.enum';
import { PurchaseService } from '@app/sign-up/services/';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent {
  constructor(
    private purchaseService: PurchaseService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private persistenceService: PersistenceService,
  ) {}

  public signOut(): void {
    this.userService.signOut()
      .pipe(
        tap(() => {
          const sessionStartTime = new Date(this.persistenceService.get('sessionStartTime')).getTime();
          const currentTime = new Date().getTime();
          const duration = `${Math.floor((currentTime - sessionStartTime) / 60000)} minutes`;
          this.analyticsService.track(AnalyticsEventEnum.STOP_SESSION, { duration });
          this.userService.signOutClear();
        }),
      )
      .subscribe();
  }

  public manageAccount(): void {
    this.purchaseService.openCustomerPortal()
      .pipe(take(1))
      .subscribe();
  }
}
