import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent {
  @Input() type: 'brands' | 'awards';

  titles = {
    brands: 'Brands Kids Know and Love!',
    awards: 'Award Winning App',
  };

  partnerLogos = {
    brands: ['dk', 'smurfs', 'trb', 'pony', 'sesame', 'barbie', 'thomas', 'prh', 'oxford'],
    awards: ['ks', 'fs', 'sn', 'mca', 'nppa', 'ptc', 'cqcm', 'ka'],
  };
}
