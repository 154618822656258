import { Injectable } from '@angular/core';
import { PlanType } from '@core/services/user/user.consts';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';
import { AnalyticsProvider } from '@core/services/analytics/analytics.provider';
import { env } from '@env';

@Injectable({ providedIn: 'root' })
export class FacebookAnalyticsProvider implements AnalyticsProvider {
  track(event: AnalyticsEventEnum, payload = {}) {
    if (env.facebookAnalyticsEnabled) {
      fbq('track', event, payload);
    }
  }

  trackChooseInterests() {
    this.track(AnalyticsEventEnum.INITIATE_CHECKOUT, {
      content_category: 'Choose_interest',
    });
  }

  trackCompleteSignUp() {
    this.track(AnalyticsEventEnum.REGISTRATION_COMPLETE, {
      content_name: 'signup',
    });
  }

  trackChoosePlan(content_ids: PlanType) {
    if (env.facebookAnalyticsEnabled) {
      fbq('track', 'AddPaymentInfo', {
        content_ids,
        content_category: 'choose plan',
      });
    }
  }

  trackPurchase() {
    // TODO: Purchase data is wrong - using mock data, this event should come from server side
    this.track(AnalyticsEventEnum.PURCHASE, {
      content_ids: '1234',
      content_name: 'Annual Subscription',
      content_type: 'Recurring',
      currency: '$',
      value: 60,
    });
  }
}
