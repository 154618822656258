<nz-modal
  [nzClassName]="class"
  [(nzVisible)]="isVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzCentered]="centered"
  [nzCloseIcon]="closeIcon"
  [nzClosable]="closable"
  (nzOnCancel)="hide()"
  [nzWidth]="width"
  [nzMaskStyle]="maskStyle"
  (nzOnOk)="hide()"
>
</nz-modal>

<ng-template #closeIcon>
  <b-icon image='cross-red.svg' type='error' size='small'></b-icon>
</ng-template>

<ng-template #modalContent>
  <div class="modal__content" [class.raw]='raw'>
    <ng-content></ng-content>
  </div>
</ng-template>
