<div class='container'>
  <div>
    <h1>OOPS... </h1>
    <h5>Error 404</h5>

    <p class="medium-16">It looks like this page no longer exists. But we have tons of fun games to amuse and entertain you.<p>
    <p class="medium-16">Check out Bookful on the
    <a rel="noopener noreferrer" target="_blank" [href]="download_ios_url">App Store</a> or
    <a rel="noopener noreferrer" target="_blank" [href]="download_android_url">Google Play</a>.</p>
  </div>

  <img src='assets/img/bookfulon.png' />
</div>
