import { Component, EventEmitter, Output } from '@angular/core';
import { User } from '@core/interfaces';
import { UserAgeEnum, UserAgeOptions } from '@core/services/user/types/user-age.enum';

@Component({
  selector: 'app-personalize',
  templateUrl: './personalize.component.html',
  styleUrls: ['./personalize.component.scss'],
})
export class PersonalizeComponent {
  @Output() ageSelected = new EventEmitter<Partial<User>>();

  ages = UserAgeOptions;
  selected = '';

  select(age: UserAgeEnum) {
    this.selected = age;
    this.ageSelected.emit({ age });
  }
}
