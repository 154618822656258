import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';
import { AnalyticsProvider } from '@core/services/analytics/analytics.provider';
import { env } from '@env';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerProvider implements AnalyticsProvider {
  constructor(private gtmService: GoogleTagManagerService) {
  }

  trackPageView(pagePath: string) {
    this.track(AnalyticsEventEnum.PAGE_VIEW, { event: 'page', pageName: pagePath });
  }

  trackAfterViewChecked() {
    this.track(AnalyticsEventEnum.AFTER_VIEW_CHECKED, { event: 'optimize.activate' });
  }

  track(event: AnalyticsEventEnum, payload: Record<string, unknown>) {
    if (env.googleAnalyticsEnabled) {
      this.gtmService.pushTag(payload);
    }
  }
}
