import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PlanType } from '@core/services/user/user.consts';
import { AnalyticsService } from '@core/services';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';
import { ChoosePlanData } from '@app/sign-up/interfaces';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss'],
})
export class ChoosePlanComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() subPlansData: ChoosePlanData;
  @ViewChild('plansContainer', { static: false }) plansContainer: ElementRef;
  @Output() planSelected = new EventEmitter<string>();
  @HostListener('window:beforeunload')
  public onBeforeUnload(): void {
    this.analyticsService.track(AnalyticsEventEnum.CLOSE_CHOOSE_PLAN);
  }

  public unsubscribe$ = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  public ngOnInit(): void {
    this.analyticsService.track(AnalyticsEventEnum.OPEN_CHOOSE_PLAN);
  }

  public ngAfterViewInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 991px)'])
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.switchBackgroundImage(state);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public select(planType: PlanType, priceId: string): void {
    this.analyticsService.track(AnalyticsEventEnum.CHOOSE_PLAN, planType);
    this.planSelected.emit(priceId);
  }

  private switchBackgroundImage(state: BreakpointState): void {
    const styles: CSSStyleDeclaration = this.plansContainer?.nativeElement?.style;
    if (styles) {
      if (state.matches) {
        styles.backgroundImage = `url(${this.subPlansData.images.background_mobile})`;
      } else {
        styles.backgroundImage = 'unset';
      }
    }
  }
}
