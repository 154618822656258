import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
// eslint-disable-next-line
import { EyeOutline, EyeInvisibleOutline } from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { ModalComponent } from '@core/modules/ui/components/modal/modal.component';
import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  StepsComponent,
  IconComponent,
  ImgComponent,
  ToggleComponent,
} from './components';

const components = [
  ButtonComponent,
  StepsComponent,
  InputComponent,
  CheckboxComponent,
  IconComponent,
  ModalComponent,
  ImgComponent,
  ToggleComponent,
];
const icons = [EyeOutline, EyeInvisibleOutline];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NzIconModule.forRoot(icons),
    NzButtonModule,
    NzStepsModule,
    NzInputModule,
    NzCheckboxModule,
    NzModalModule,
    NzMessageModule,
    NzFormModule,
    NzSkeletonModule,
    NzSwitchModule,
  ],
  declarations: components,
  exports: [
    NzFormModule,
    NzMessageModule,
    NzSkeletonModule,
    NzIconModule,
    ...components,
  ],
})
export class UiModule {}
