import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  hideBrands$ = new BehaviorSubject(false);
  dropdownIsShown = false;
  constructor(private router: Router, private route: ActivatedRoute) {
    router.events
      .pipe(
        // @ts-ignore
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          const hideBrands = route.snapshot.firstChild?.data.hideBrands || false;
          this.hideBrands$.next(hideBrands);
        }),
      ).subscribe();
  }

  onToggleDropdown(dropdownIsShown: boolean): void {
    this.dropdownIsShown = dropdownIsShown;
  }
}
