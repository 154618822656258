import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { env } from '@env';
import { AnonUser, ResetPassword, SignInUser, SignUpUser, UpdateUser, User } from '@core/interfaces';
import { HttpService, PersistenceService, DeviceService, RequestOptions } from '@core/services';
import { AuthResponseDto } from '@core/services/user/types/auth-response.dto';
import {
  AdditionalUserData,
  AuthErrorCodes, QUERY_OPTIONS_KEY, SESSION_STARTED_KEY,
  UNITY_VERSION,
} from '@core/services/user/user.consts';
import { UserMapper } from '@core/services/user/user-mapper';
import { UiConfigService } from '@core/modules/ui/services/ui-config.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  data: User = this.persistenceService.get('user');

  isLoggedIn$ = new BehaviorSubject(Boolean(this.access_token));
  token$ = new BehaviorSubject(this.access_token);
  id$ = new BehaviorSubject(this.data?.id);

  constructor(
    private http: HttpService,
    private persistenceService: PersistenceService,
    private deviceService: DeviceService,
    private userMapper: UserMapper,
    private uiConfigService: UiConfigService,
    private window: Window,
  ) {
    this.update = this.update.bind(this);
    this.signUpWithEmail = this.signUpWithEmail.bind(this);
  }

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  get sessionInitialized(): boolean {
    return this.persistenceService.get(SESSION_STARTED_KEY) || this.persistenceService.get('sessionEndTime');
  }

  get registrationCompleted(): boolean {
    return !!this.data?.account_id
      && !!this.data?.purchased
      && !!this.data.age
      && !!this.data.interested_in?.length;
  }

  get access_token() {
    return this.data?.access_token || '';
  }

  get email(): string | null {
    return this.data?.email || null;
  }

  signUpAnon(): Observable<User> {
    const data = this.getAnonUserData();
    const options: RequestOptions = {};

    return this.http
      .post<AuthResponseDto>('v1/auth/anon-user', data, this.getRequestOptions(options))
      .pipe(
        tap(user => this.uiConfigService.mapAuthDto(user)),
        tap(user => this.updateDataWithAuthRequest(user, { version: data.web_version })),
        tap(() => this.persistenceService.set(SESSION_STARTED_KEY, true)),
        map(() => this.data),
      );
  }

  signUpWithEmail({ subscribed_to_newsletter, ...data }: SignUpUser): Observable<User> {
    const signUpUser = this.http
      .post<AuthResponseDto>('v1/auth/account/signup/email', data, this.getRequestOptions())
      .pipe(tap((dto) => this.updateDataWithAuthRequest(dto, { email: data.email })));

    const subscribeUser = this.subscribeToNewsletter(subscribed_to_newsletter);

    return forkJoin([signUpUser, subscribeUser])
      .pipe(map(() => this.data));
  }

  subscribeToNewsletter(subscribed_to_newsletter = false) {
    return subscribed_to_newsletter
      ? this.update({ subscribed_to_newsletter })
      : of({ subscribed_to_newsletter });
  }

  update(data: UpdateUser): Observable<User> {
    return this.http
      .put<AnonUser>('v1/anon-users/me', data, this.getRequestOptions())
      .pipe(
        tap(user => this.updateDataWithAnonUser(user)),
        map(() => this.data),
      );
  }

  get(): Observable<User> {
    return this.http
      .get<AnonUser>('v1/anon-users/me', this.getRequestOptions())
      .pipe(
        tap(user => this.updateDataWithAnonUser(user)),
        map(() => this.data),
      );
  }

  login(data: SignInUser) {
    return this.http
      .post<AuthResponseDto>('v1/auth/account/login/email', data, this.getRequestOptions())
      .pipe(
        tap((dto) => {
          this.updateDataWithAuthRequest(dto, { email: data.email });
          this.persistenceService.remove('refresh');
        }),
        map(() => this.data),
      );
  }

  signOut(): Observable<unknown> {
    return this.http
      .post('v1/auth/account/logout', {}, this.getRequestOptions({ headers: { access_token: this.access_token } }));
  }

  forgotPassword(data: Partial<SignInUser>) {
    return this.http.post<string>('v1/accounts/forgot-password', data, this.getRequestOptions());
  }

  resetPassword({ new_password, token }: ResetPassword) {
    return this.http.post<boolean>(
      'v1/accounts/reset-password',
      { new_password },
      { headers: { access_token: token }, noMsg: true },
    );
  }

  private updateDataWithAuthRequest(data: AuthResponseDto, additional?: AdditionalUserData) {
    this.data = { ...this.userMapper.mapAuthDTOToAppUser(data, this.data) };
    this.data.email = additional?.email || this.data?.email;
    this.data.web_version = additional?.version || this.data?.web_version;
    this.saveData();
  }

  public updateUserState(user: AnonUser, additional: AdditionalUserData = {}) {
    const data = { ...this.userMapper.mapAnonUserToPartialUser(user, this.data) };
    this.data = { ...this.data, ...data, ...additional };
    this.saveData();
  }

  public signOutClear() {
    this.persistenceService.clearCache();
    this.window.location.href = '/';
  }

  private updateDataWithAnonUser(data: AnonUser) {
    this.data = this.userMapper.mapAnonUserToPartialUser(data, this.data);
    this.saveData();
  }

  private getAnonUserData() {
    return {
      config: {
        overwrite: {
          [UNITY_VERSION]: true,
        },
      },
      application_id: env.appId,
      web_version: env.version,
      device: this.deviceService.getData(),
    };
  }

  private saveData() {
    this.persistenceService.set('user', this.data);
    this.isLoggedIn$.next(Boolean(this.access_token));
    this.token$.next(this.access_token);
  }

  private getRequestOptions(options: RequestOptions = {}) {
    const { access_token } = this.getAndClearQueryOptions();
    if (access_token) {
      options.headers = { access_token };
    }
    return {
      ...options,
      errorCodes: AuthErrorCodes,
    };
  }

  private getAndClearQueryOptions() {
    const queryOptions = this.persistenceService.get(QUERY_OPTIONS_KEY);
    this.persistenceService.remove(QUERY_OPTIONS_KEY);
    return queryOptions || {};
  }
}
