export interface Goods {
  goods_id: string;
  goods_type: GoodsTypeEnum;
  goods_data: Record<string, unknown> | any; // TODO fix types
}

export enum GoodsTypeEnum {
  Product = 'product',
  Subscription = 'subscription',
  Promotion = 'promotion',
  Book = 'book',
  Points = 'points'
}
