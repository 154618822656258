<b-button size="small" (click)="toggle()">Toggle Dev Utils</b-button>
<div *ngIf = "devToolsOpen" class="dev-tools">
<b-button size="small" (click)="resetSession()">
  Clear Session
</b-button>
  <br>
  <b-button size="small" (click)="getUserData()">
    Session Data
  </b-button>
</div>
