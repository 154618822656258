import { Component, Input } from '@angular/core';

export type ModalCallbackFn = (data?: unknown) => void;

@Component({
  selector: 'b-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() isVisible = false;
  @Input() closable = true;
  @Input() centered = true;
  @Input() title: string;
  @Input() raw: boolean;
  @Input() width = 480;
  @Input() class = 'modal';
  @Input() maskStyle = {};

  private onHideFns: ModalCallbackFn[] = []
  private onShowFns: ModalCallbackFn[] = []

  registerOnHide(fn: ModalCallbackFn) {
    if (!this.onHideFns.includes(fn)) {
      this.onHideFns.push(fn);
    }
  }

  registerOnShow(fn: ModalCallbackFn) {
    if (!this.onShowFns.includes(fn)) {
      this.onShowFns.push(fn);
    }
  }

  show(data?: unknown): void {
    this.onShowFns.forEach(fn => fn(data));
    this.isVisible = true;
  }

  hide(force?: boolean): void {
    if (!force && !this.closable) return;

    this.onHideFns.forEach(fn => setTimeout(fn, 0));
    this.isVisible = false;
  }
}
