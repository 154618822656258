import { Component, Input } from '@angular/core';
import { NzSizeDSType } from 'ng-zorro-antd/core/types';
import { NzDirectionType } from 'ng-zorro-antd/steps';
import { Step } from '@core/interfaces';

@Component({
  selector: 'b-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
  @Input() steps: Step[] = [];
  @Input() current = 0;
  @Input() size: NzSizeDSType = 'small';
  @Input() labelPlacement: NzDirectionType = 'horizontal';

  getIndex(index: number): number {
    return this.steps
      .slice(0, index)
      .filter(s => !s.nextHidden)
      .length + 1;
  }
}
