import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private route: ActivatedRoute) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = req.headers.get('access_token') || this.userService.access_token;

    if (token) {
      // eslint-disable-next-line no-param-reassign
      req = req.clone({
        headers: req.headers.set('access_token', token),
      });
    }

    return next.handle(req);
  }
}
