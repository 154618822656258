<ng-container *ngIf="subPlansData">
  <div #plansContainer class='choose-plan-container' [style.--background-img]="'url(' + subPlansData.images.background_desktop + ')'">
    <div class="title-container">
      <img src="/assets/img/choose-plan-icons/left-stars.svg" />
      <h2 class="title">{{subPlansData.strings.main_title}}</h2>
      <img src="/assets/img/choose-plan-icons/right-stars.svg" />
    </div>
    <div class="plans-container">
        <div id="left-plan" class="plan" *ngIf="subPlansData.goods[0].goods_data as leftPlan">
          <img [src]="leftPlan.images.card_img" />
          <button id="left-plan-btn" (click)="select(leftPlan.strings.sub_type, leftPlan.store_id)" class="plan-btn" [ngStyle]="{ 
            'background': leftPlan.colors.btn_color 
          }">{{leftPlan.strings.btn_text}}</button>
        </div>
        <ng-container *ngIf="subPlansData.goods.length === 3">
          <div id="middle-plan" class="plan" *ngIf="subPlansData.goods[1].goods_data as middlePlan">
            <img [src]="middlePlan.images.card_img" />
            <button id="middle-plan-btn" (click)="select(middlePlan.strings.sub_type, middlePlan.store_id)" class="plan-btn" [ngStyle]="{ 
              'background': middlePlan.colors.btn_color 
            }">{{middlePlan.strings.btn_text}}</button>
          </div>
        </ng-container>
        <div id="right-plan" class="plan" *ngIf="subPlansData.goods[subPlansData.goods.length - 1].goods_data as rightPlan">
          <img [src]="rightPlan.images.card_img" />
          <button id="right-plan-btn" (click)="select(rightPlan.strings.sub_type, rightPlan.store_id)" class="plan-btn" [ngStyle]="{ 
            'background': rightPlan.colors.btn_color 
          }">{{rightPlan.strings.btn_text}}</button>
        </div>
    </div>
  </div>
</ng-container>
