export enum UserAgeEnum {
  THREE_AND_UNDER = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT_AND_MORE = '8',
}
export type UserAgeOption = { copy: string, enum: UserAgeEnum };

export const UserAgeOptions: UserAgeOption[] = [
  {
    copy: '3 & under',
    enum: UserAgeEnum.THREE_AND_UNDER,
  },
  {
    copy: '4',
    enum: UserAgeEnum.FOUR,
  },
  {
    copy: '5',
    enum: UserAgeEnum.FIVE,
  },
  {
    copy: '6',
    enum: UserAgeEnum.SIX,
  },
  {
    copy: '7',
    enum: UserAgeEnum.SEVEN,
  },
  {
    copy: '8+',
    enum: UserAgeEnum.EIGHT_AND_MORE,
  },
];
