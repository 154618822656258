import { Injectable } from '@angular/core';
import { AnonUser, User } from '@core/interfaces';
import { AuthResponseDto } from '@core/services/user/types/auth-response.dto';
import { Goods, GoodsTypeEnum } from '@core/services/user/types/goods.interface';

@Injectable({ providedIn: 'root' })
export class UserMapper {
  constructor() {
  }

  public extractSubscriptionFromGoods(goods: Goods[]) {
    return goods.filter(g => g.goods_type === GoodsTypeEnum.Subscription)[0];
  }

  public mapAnonUserToPartialUser(anon: AnonUser, current?: Partial<User>): Partial<User> {
    return {
      ...current,
      id: anon.id,
      user_service_account_id: anon.user_service_account_id,
      interested_in: anon.interested_in || current?.interested_in,
      mixpanel_id: anon.mixpanel_id || current?.mixpanel_id,
      age: anon.age || current?.age,
      subscribed_to_newsletter: !!(anon.subscribed_to_newsletter || current?.subscribed_to_newsletter),
      purchased: !!anon.goods && !!this.extractSubscriptionFromGoods(anon.goods),
      session_count: anon.session_count,
      account_id: anon.account_id,
    };
  }

  public mapAuthDTOToAppUser(dto: AuthResponseDto, current?: Partial<User>): User {
    return {
      ...this.mapAnonUserToPartialUser(dto.user, current),
      access_token: dto.access_token || current?.access_token,
    };
  }
}
