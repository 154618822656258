<ng-container *ngIf='purchaseCompleted'>
  <b-icon image='checkmark.svg' type='success'></b-icon>
  <h2>Purchase Successful!</h2>
  <p class='medium-16'>Get Ready for Some <br> Book-Filled Fun and Games!</p>
  <b-button routerLink='/' size='large'>let's go</b-button>
</ng-container>

<ng-container *ngIf='!purchaseCompleted'>
  <b-icon image='exclamation.svg' type='error' class='exclamation'></b-icon>
  <h2>Could not complete <br> the purchase </h2>
  <b-button (click)='goBack()' size='large'>back</b-button>
</ng-container>
