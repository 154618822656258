import { Component } from '@angular/core';
import { BROWSER_WEBGL_VERSIONS } from '@app/core/constants';

@Component({
  selector: 'app-unsupported-browsers',
  templateUrl: './unsupported-browsers.component.html',
  styleUrls: ['./unsupported-browsers.component.scss'],
})
export class UnsupportedBrowsersComponent {
  public readonly BROWSER_WEBGL_VERSIONS = BROWSER_WEBGL_VERSIONS;
}
