import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { PersistenceService } from '@core/services/persistence/persistence.service';
import { DEVICE_TYPE, DEVICE_UUID_KEY } from '@core/services/device/device.constants';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private deviceId = this.setupUUID();

  constructor(private persistenceService: PersistenceService) {
  }

  private setupUUID() {
    let id = this.persistenceService.get(DEVICE_UUID_KEY);
    if (!id) {
      id = `WEB_${uuidv4()}`;
      this.persistenceService.set(DEVICE_UUID_KEY, id);
    }
    return id;
  }

  get id(): string {
    return this.deviceId;
  }

  public getData() {
    return {
      model: this.getBrowserNameAndVersion(),
      type: DEVICE_TYPE,
      unity_id: this.deviceId,
      width_px: window.innerWidth,
      height_px: window.innerHeight,
      manufacturer: navigator.vendor,
    };
  }

  // TODO check it on few devices, might give wrong resutls
  // source: https://stackoverflow.com/a/5918791
  public getBrowserNameAndVersion() {
    const ua = navigator.userAgent;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

      return `IE ${tem[1] || ''}`;
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

    tem = ua.match(/version\/(\d+)/i);

    if (tem != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  isMobile() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some(re => navigator.userAgent.match(re));
  }
}
