import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { SignUpContainer } from '@app/sign-up/container/sign-up.container';
import { UiModule } from '@core/modules';
import { SignUpResolver } from '@app/sign-up/services/sign-up.resolver';
import { SignUpRoutingModule } from './sign-up-routing.module';
import {
  ChoosePlanComponent,
  InterestsComponent,
  PersonalizeComponent,
  SignUpComponent,
  PurchaseCompleteComponent,
} from './components';

@NgModule({
  declarations: [
    SignUpContainer,
    PersonalizeComponent,
    InterestsComponent,
    SignUpComponent,
    ChoosePlanComponent,
    PurchaseCompleteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SignUpRoutingModule,
    FlexLayoutModule,
    UiModule,
    LayoutModule,
  ],
  providers: [SignUpResolver],
})
export class SignUpModule { }
