import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Library } from '@app/library/interfaces/library.interface';
import { Observable } from 'rxjs';
import { LibraryService } from '@app/library/services/library.service';

@Injectable()
export class LibraryResolver implements Resolve<Library> {
  constructor(private libraryService: LibraryService) {}

  resolve(): Observable<Library> {
    // cant cache books due to shelves changing based on user interaction
    return this.libraryService.fetch();
  }
}
