export enum AnalyticsEventEnum {
  NOT_FOUND = 'Web - Error 404',
  RESET_PASSWORD = 'Web - Password reset',
  FORGOT_PASSWORD = 'Web - Forgot password',
  START_SESSION = 'Start web session',
  STOP_SESSION = 'Web - Session End',
  ANONYMOUS_LOGIN = 'Web - Anonymous Login',
  SELECTED_AGE = 'Web - Next on age',
  SELECTED_INTERESTS = 'Web - Next on interests',
  SIGNED_UP = 'Web - Signed Up',
  CHOOSE_PLAN = 'Web - Select product',
  OPEN_CHOOSE_PLAN = 'Web - Open subscription page',
  CLOSE_CHOOSE_PLAN = 'Web - Close subscription page',
  SELECTED_PRODUCT = 'Web - Select product',
  SIGNED_IN = 'Web - Signed In',
  AFTER_VIEW_CHECKED = 'Web - ViewChecked',
  PAGE_VIEW = 'Web - PageView',
  INITIATE_CHECKOUT = 'Web - InitiateCheckout',
  REGISTRATION_COMPLETE = 'Web - CompleteRegistration',
  PURCHASE = 'Web - Purchase',
  BOOK_SPREAD_CHANGED = 'Web - Open book spread',
  BOOK_CLOSED = 'Web - Close book',
  BOOK_OPENED = 'Web - Open book',
  BOOK_FINISHED = 'Web - Book finished',
  BOOK_SLIDER_USED = 'Web - Slider interaction',
  BOOK_READ_TO_ME = 'Web - Open read scene with mode',
  OPEN_LIBRARY = 'Web - Open library',
  WHATS_NEXT_POPUP_BOOK = 'Web - What\'s next open book',
  WHATS_NEXT_POPUP_BACK = 'Web - What\'s next back to library',
  SORT_ON_LIBRARY = 'Web - Sort on library',
  BACK_TO_LIBRARY_WEBGL = 'Web - Back to library from book page',
  BACK_TO_LIBRARY_DOWNLOAD = 'Web - Back to library from download app page',
  OPEN_GAME_LOBBY = 'Web - Open park'
}
