import { Component } from '@angular/core';
import { URLS } from '@core/constants';
import { AnalyticsService } from '@core/services';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  download_ios_url = URLS.DOWNLOAD_BOOKFUL_APPSTORE;
  download_android_url = URLS.DOWNLOAD_BOOKFUL_GOOGLEPLAY;

  constructor(private analyticsService: AnalyticsService) {
    analyticsService.track(AnalyticsEventEnum.NOT_FOUND);
  }
}
