import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private message: NzMessageService) {}

  error(msg: string) {
    this.message.error(msg, { nzDuration: 5000, nzPauseOnHover: true });
  }
}
