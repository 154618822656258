import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpContainer } from '@app/sign-up/container/sign-up.container';
import { SignUpResolver } from '@app/sign-up/services/sign-up.resolver';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  component: SignUpContainer,
  resolve: { user: SignUpResolver },
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignUpRoutingModule { }
