import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PersistenceService } from '@core/services';
import { UIConfig } from '@core/modules/ui/services/ui-config.interface';
import { AuthResponseDto } from '@core/services/user/types/auth-response.dto';

@Injectable({ providedIn: 'root' })
export class UiConfigService {
  data: UIConfig = this.persistenceService.get('ui-config');

  settingsShown$ = new BehaviorSubject(false);

  constructor(private persistenceService: PersistenceService) {}

  mapAuthDto(dto: AuthResponseDto) {
    this.data = {
      stepsOrder: dto.user.config?.stepsOrder?.split(/,\s?/),
    };

    this.persistenceService.set('ui-config', this.data);
  }

  toggleSettings() {
    this.settingsShown$.next(!this.settingsShown$.getValue());
  }
}
