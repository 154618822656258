import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-purchase-complete',
  templateUrl: './purchase-complete.component.html',
  styleUrls: ['./purchase-complete.component.scss'],
})
export class PurchaseCompleteComponent {
  @Input() purchaseCompleted: boolean;
  @Output() back = new EventEmitter();

  goBack() {
    this.back.emit();
  }
}
