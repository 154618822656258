import { Component, OnInit } from '@angular/core';
import { UserService } from '@core/services';

@Component({
  selector: 'dev-utils',
  templateUrl: './dev-utils.component.html',
  styleUrls: ['./dev-utils.component.scss'],
})
export class DevUtilsComponent implements OnInit {
  constructor(private userService: UserService) { }
  devToolsOpen = true;

  ngOnInit(): void {
  }

  toggle() {
    this.devToolsOpen = !this.devToolsOpen;
  }

  getUserData() {
    const { data } = this.userService;
    this.downloadFile(JSON.stringify(data, null, 2));
  }

  private downloadFile(data: string) {
    const blob = new Blob([data], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  resetSession() {
    localStorage.clear();
    window.location.reload();
  }
}
