import { Component, Input } from '@angular/core';
import { ButtonType } from '@core/modules/ui/components/button/button-constants';

@Component({
  selector: 'b-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() loading = false;
  @Input() disabled = false;
  @Input() type: string = ButtonType.PRIMARY;
  @Input() selected = false;
  @Input() outlined = false;
  @Input() size = 'default';

  get primary() {
    return this.type === ButtonType.PRIMARY;
  }

  get secondary() {
    return this.type === ButtonType.SECONDARY;
  }

  get purple() {
    return this.type === ButtonType.PURPLE;
  }

  get success() {
    return this.type === ButtonType.SUCCESS;
  }

  get error() {
    return this.type === ButtonType.ERROR;
  }
}
