import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DeviceService } from '@core/services/device/device.service';

@Injectable({ providedIn: 'root' })
export class MobileGuard implements CanActivate {
  constructor(private deviceService: DeviceService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { path } = route.routeConfig!;
    const isMobile = this.deviceService.isMobile();

    if (path === '' && isMobile) {
      this.router.navigate(['/download']);
      return false;
    }

    if (path === 'download' && !isMobile) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
