<div fxLayout='row' fxLayoutAlign='space-between center'>
  <ng-container *ngIf='showLibraryUI$ | async'>
  <library-sort ></library-sort>
  </ng-container>
  <div class="navigation">
    <b-icon class="library" size="x-large" *ngIf='showBookUI$ | async' image='library.svg'
            (click)="backToLibrary()"></b-icon>
  </div>
  <ng-container *ngIf="devToolsEnabled">
    <dev-utils></dev-utils>
  </ng-container>
  <img class="logo" src='assets/img/logo-white.png'>

  <div class='actions'>

    <b-icon *ngIf="showGameMap$ | async" image='game-map.png' (click)="openDownloadPage()"></b-icon>
    <b-icon *ngIf='showSettingsUI$ | async' image='settings.svg' (click)='toggleSettings()'></b-icon>
    <b-icon *ngIf='userService.registrationCompleted' image='account.svg' (click)='toggleUserDropdown()'></b-icon>
  </div>
</div>
