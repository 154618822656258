import { Component } from '@angular/core';
import { AnalyticsService } from '@app/core/services';
import { AnalyticsEventEnum } from '@app/core/services/analytics/analytics-event.enum';
import { LibraryService } from '@app/library/services';
import { librarySortOptions } from '@app/library/services/constants';

@Component({
  selector: 'library-sort',
  templateUrl: './library-sort.component.html',
  styleUrls: ['./library-sort.component.scss'],
})
export class LibrarySortComponent {
  constructor(
    private libraryService: LibraryService,
    private analyticsService: AnalyticsService,
  ) {}

  nextIndex() {
    const currentSortCategory = this.getCurrentSortOption();
    const currentIndex = librarySortOptions.indexOf(currentSortCategory);
    return (currentIndex + 1) % (librarySortOptions.length);
  }

  getCurrentSortOption() {
    return this.libraryService.getCurrentSortOption();
  }

  changeSort() {
    this.analyticsService.track(AnalyticsEventEnum.SORT_ON_LIBRARY);
    const next = this.nextIndex();
    this.libraryService.fetchBySortOrder(next)
      .subscribe();
  }

  getNext() {
    return librarySortOptions[this.nextIndex()];
  }
}
