import { Inject, Injectable } from '@angular/core';
import { AnalyticsService, HttpService, PersistenceService } from '@core/services';
import { env } from '@env';
import { delay, tap } from 'rxjs/operators';
import { CheckoutSessionRes, CustomerPortalRes } from '@app/sign-up/services/purchase/purchase.interface';
import { DOCUMENT, Location } from '@angular/common';
import { AlertService } from '@core/services/alert.service';

@Injectable({ providedIn: 'root' })
export class PurchaseService {
  public readonly endpoint = 'v2/payments/stripe';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly http: HttpService,
    private readonly alertService: AlertService,
    private readonly analyticsService: AnalyticsService,
    private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.window = this.document.defaultView!; // todo handle non-browser/web-worker cases somehow
    this.createCheckoutSession = this.createCheckoutSession.bind(this);
  }

  createCheckoutSession(priceId: string) {
    return this.http
      .post<CheckoutSessionRes>(`${this.endpoint}/checkout-session`, {
        priceId,
        success_url: Location.joinWithSlash(this.window.location.origin, env.stripe.success_url),
        cancel_url: Location.joinWithSlash(this.window.location.origin, env.stripe.cancel_url),
        quantity: 1,
      })
      .pipe(
        tap(res => {
          this.window.location.href = res.stripeCheckoutSessionUrl;
        }),
        delay(5000), // TODO re-do? (do not show complete step and wait for redirect)
      );
  }

  syncCheckoutSession(sessionId: string) {
    return this.http.post(`${this.endpoint}/checkout-session/${sessionId}/sync`, {}, { noMsg: true });
  }

  openCustomerPortal() {
    return this.http.post<CustomerPortalRes>(`${this.endpoint}/billing-portal-session`,
      {
        return_url: this.window.location.href,
      })
      .pipe(
        tap(res => {
          this.persistenceService.set('stripe-url', res.customerPortalSessionUrl);
          this.window.location.href = res.customerPortalSessionUrl;
        }),
      );
  }
}
