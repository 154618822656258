<nz-steps #stepper [nzCurrent]='current' [nzSize]='size' [nzLabelPlacement]='labelPlacement'>
  <ng-container *ngFor='let s of steps'>
    <nz-step
      #step
      [nzIcon]='icon'
      [nzTitle]='s.title'
      [class.next-hidden]='s.nextHidden'
      [class.next-finished]='stepper.nzCurrent > step.index + 1'
    ></nz-step>

    <ng-template #icon>
      <b-icon renderMode='text' [disabled]='stepper.nzCurrent < step.index'>
        {{ getIndex(step.index) }}
      </b-icon>
    </ng-template>
  </ng-container>
</nz-steps>


