import { Component, forwardRef, Injector, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'b-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleComponent),
    multi: true,
  }],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() value: boolean;
  @Input() label: string;

  control: NgControl | any;
  disabled = false;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.control = this.injector.get(NgControl);
  }

  onChange(e: any) {
    this.value = e;
  }

  onTouched() {
  }

  writeValue(value: any) {
    this.value = value || '';
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  toggle() {
    this.control.control.setValue(!this.value);
  }
}
