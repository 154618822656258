export enum EventsActionEnum {
  OPENED = 'opened',
  STARTED = 'started',
  FINISHED = 'finished',
}

export enum EventsCategoryEnum {
  SESSION = 'session',
  LIBRARY = 'library',
  BOOK = 'book',
  BOOK_SPREAD = 'book_spread',
  CHOOSE_PLAN = 'choose_plan',
}
