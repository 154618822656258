<div
  [class.has-error]='control.touched && control.invalid'
  class='form-item'
  fxLayout='row'
  fxLayout.xs='column'
>

<label
 *ngIf="label"
  attr.for='{{id}}-c1'
  fxFlex.gt-xs='30%'
  >{{label}}:
</label>

  <div class='form-control'>
    <input
      id='{{id}}-c1'
      [placeholder]='placeholder'
      [formControl]='control.control'
      [type]='hide ? type : "text"'
    />

    <div class='icon-container' *ngIf='type === "password"'>
      <b-icon
        image='eye-blue.svg'
        [transparent]='true'
        (click)='hide = !hide'
      ></b-icon>
    </div>

    <caption class='error-hint' *ngIf='control.touched'>
      <ng-container *ngIf='control.hasError("required")'>Field is required!</ng-container>
      <ng-container *ngIf='control.hasError("email")'>Invalid email!</ng-container>
      <ng-container *ngIf='control.hasError("minlength")'>{{displayName}} too short!</ng-container>
      <ng-container *ngIf='control.hasError("mismatch")'>{{displayName}} must match!</ng-container>
    </caption>
  </div>
</div>
