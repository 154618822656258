import { env } from '@env';

export const AuthErrorCodes = {
  2000: {
    name: 'ACCOUNT_ALREADY_SIGNED_UP',
    msg: 'Account already signed up',
  },
  2004: {
    name: 'ACCOUNT_DOES_NOT_EXIST',
    msg: 'Account does not exist',
  },
  2005: {
    name: 'ACCOUNT_DID_NOT_RESTORE_PASSWORD',
    msg: 'Account did not restore password',
  },
  2006: {
    name: 'ACCOUNT_PASSWORD_MISMATCH',
    msg: 'Account password mismatch',
  },
  2007: {
    name: 'ACCOUNT_NOT_VERIFIED',
    msg: 'Account is not verified',
  },
};

export type PlanType = 'annual' | 'lifetime';
export const UNITY_VERSION = 'unity_2020';
export const SESSION_STARTED_KEY = 'session_started';
export const QUERY_OPTIONS_KEY = 'query_options';

export const planTypeLookup = {
  annual: env.stripe.prices.annual,
  lifetime: env.stripe.prices.lifetime,
};

export interface AdditionalUserData {
  email?: string;
  version?: string;
}
