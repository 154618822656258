import { Component, EventEmitter, Output } from '@angular/core';
import { User } from '@core/interfaces';
import { UserInterestEnum, UserInterestOptions } from '@core/services/user/types/user-interest.enum';

@Component({
  selector: 'app-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss'],
})
export class InterestsComponent {
  @Output() interestsSelected = new EventEmitter<Partial<User>>();
  @Output() validChanged = new EventEmitter<boolean>();

  interests = UserInterestOptions;
  selected: UserInterestEnum[] = [];

  select(interest: UserInterestEnum) {
    this.selected = this.selected.includes(interest)
      ? this.selected.filter(i => i !== interest)
      : this.selected.concat(interest);

    this.interestsSelected.emit({ interested_in: this.selected });
    this.validChanged.emit(!!this.selected.length);
  }
}
