import { Component, forwardRef, Input, OnInit, Injector, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'b-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
  }],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() value: boolean;

  control: NgControl | any;
  disabled = false;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.control = this.injector.get(NgControl);
  }

  @HostListener('click', ['$event.target'])
  onClick() {
    const value = !this.value;

    this.onChange(value);
    this.writeValue(value);
  }

  onChange(e: any) {
    this.value = e;
  }

  onTouched() {}

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) { this.disabled = isDisabled; }
}
