import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { env } from '@env';
import { AppModule } from './app/app.module';

if (env.production) {
  enableProdMode();
}

// Can't push script from Angular CLI due to rejecting external URLs
// This script loads the WebGL loader and enables the app to instantiate Unity
const webglScriptURL = `${env.webglUrl}/Build/WebGLPlayer.loader.js`;
const webglScriptElement = window.document.createElement('script');
webglScriptElement.async = true;
webglScriptElement.src = webglScriptURL;
window.document.head.append(webglScriptElement);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
