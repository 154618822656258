import { omit } from 'lodash';
import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { env } from '@env';
import { UserService } from '@core/services';
import { skipWhile } from 'rxjs/operators';
import { User } from '@core/interfaces';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';

@Injectable({ providedIn: 'root' })
export class MixpanelProvider {
  userId: string;

  constructor(private userService: UserService) {
    mixpanel.init(env.mixpanelToken);

    userService.id$
      .pipe(skipWhile(v => !v))
      .subscribe((id) => {
        if (userService.email) {
          mixpanel.identify(userService.email);
        } else if (!this.userId && id) {
          mixpanel.identify(id);
        }
        this.userId = id!;
      });
  }

  trackOpenLibrary(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.OPEN_LIBRARY, data);
  }

  trackStartSession(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.START_SESSION, data);
  }

  trackStopSession(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.STOP_SESSION, data);
  }

  trackAnonymousLogin(data: Partial<User>) {
    this.track(AnalyticsEventEnum.ANONYMOUS_LOGIN, data, true);
  }

  trackChooseAge(data: Partial<User>) {
    this.track(AnalyticsEventEnum.SELECTED_AGE, { age: data.age }, true);
  }

  trackChooseInterests(data: Partial<User>) {
    this.track(AnalyticsEventEnum.SELECTED_INTERESTS, data, true);
  }

  trackCompleteSignUp(data: User, subscribed_to_newsletter: boolean) {
    const registerData = { newsletter_check_mark_on: subscribed_to_newsletter, ...omit(data, 'password') };
    mixpanel.alias(String(data.email), this.userId);
    this.extendUser({ email: data!.email, subscribed_to_newsletter });
    this.track(AnalyticsEventEnum.SIGNED_UP, registerData, true);
  }

  trackOpenChoosePlan() {
    this.track(AnalyticsEventEnum.OPEN_CHOOSE_PLAN);
  }

  trackCloseChoosePlan() {
    this.track(AnalyticsEventEnum.CLOSE_CHOOSE_PLAN);
  }

  trackChoosePlan() {
    this.track(AnalyticsEventEnum.SELECTED_PRODUCT);
  }

  trackSignIn() {
    this.track(AnalyticsEventEnum.SIGNED_IN);
  }

  trackForgotPassword() {
    this.track(AnalyticsEventEnum.FORGOT_PASSWORD);
  }

  trackSliderUsed(data: Partial<User>) {
    this.track(AnalyticsEventEnum.BOOK_SLIDER_USED, data);
  }

  trackResetPassword() {
    this.track(AnalyticsEventEnum.RESET_PASSWORD);
  }

  track404() {
    this.track(AnalyticsEventEnum.NOT_FOUND);
  }

  trackWhatsNextOpenBook(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.WHATS_NEXT_POPUP_BOOK, data);
  }

  trackWhatsNextBackToLibrary() {
    this.track(AnalyticsEventEnum.WHATS_NEXT_POPUP_BACK);
  }

  trackSortOnLibrary() {
    this.track(AnalyticsEventEnum.SORT_ON_LIBRARY);
  }

  trackBackToLibraryFromWebGl(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BACK_TO_LIBRARY_WEBGL, data);
  }

  trackBackToLibraryFromDownload(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BACK_TO_LIBRARY_DOWNLOAD, data);
  }

  trackOpenGameLobby(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.OPEN_GAME_LOBBY, data);
  }

  trackBookReadToMe(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BOOK_READ_TO_ME, data);
  }

  trackBookSpreadChanged(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BOOK_SPREAD_CHANGED, data);
  }

  trackBookClosed(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BOOK_CLOSED, data);
  }

  trackBookOpened(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BOOK_OPENED, data);
  }

  trackBookFinished(data: Record<string, unknown>) {
    this.track(AnalyticsEventEnum.BOOK_FINISHED, data);
  }

  public track(event: AnalyticsEventEnum, data?: Record<string, unknown>, extendUser = false) {
    data = omit(data, 'password', 'new_password');
    if (extendUser) {
      this.extendUser(data);
    }
    mixpanel.track(event, data);
  }

  private extendUser(data: Partial<User>) {
    const updateData: Record<string, unknown> = { ...data };
    if (data.id) {
      updateData.user_id = data.id;
    }
    // extend keys with $ prefix so MixPanel will parse them properly
    mixpanel.people.set(
      Object
        .entries(updateData)
        .reduce(
          (acc, [k, v]) => ({ ...acc, [`$${k}`]: v }),
          {},
        ),
    );
  }
}
