import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'b-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent {
  @HostBinding('style.background') bg: string;

  @Input() src: string;
  @Input() alt = 'img';
  @Input() width = 140;
  @Input() height = 197;

  loaded = false;

  onLoaded() {
    this.loaded = true;
    this.bg = 'transparent';
  }
}
