<button
  [ngClass]='{
    btn: true,
    primary,
    selected,
    secondary,
    purple,
    outlined,
    disabled,
    success,
    error
  }'
  [class]='size'
  [disabled]='disabled'
>
  <i nz-icon nzType='loading' *ngIf='loading'></i>

  <ng-content></ng-content>
</button>
