import { Injectable } from '@angular/core';
import { BROWSER_WEBGL_VERSIONS } from '../constants';
import { QueryParam } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(
    private window: Window,
  ) {}

  public isBrowserSupported(): boolean {
    const { userAgent } = this.window.navigator;
    const isIE = /msie\s|trident/i.test(userAgent);
    const isFirefox = userAgent.includes('Firefox/');
    const isEdge = userAgent.includes('Edg/');
    const isChrome = userAgent.includes('Chrome/');
    const isSafari = userAgent.includes('Safari/');
    let browserVersion = 0;

    if (isFirefox) {
      browserVersion = Number(userAgent.split('Firefox/')[1].slice(0, 2));
    } else if (isEdge) {
      browserVersion = Number(userAgent.split('Edg/')[1].slice(0, 2));
    } else if (isChrome) {
      browserVersion = Number(userAgent.split('Chrome/')[1].slice(0, 2));
    } else if (isSafari) {
      browserVersion = Number(userAgent.split('Version/')[1].slice(0, 2));
    }

    return !isIE
      || (isFirefox && browserVersion >= BROWSER_WEBGL_VERSIONS.FIREFOX)
      || (isChrome && browserVersion >= BROWSER_WEBGL_VERSIONS.CHROME)
      || (isEdge && browserVersion >= BROWSER_WEBGL_VERSIONS.EDGE)
      || (isSafari && browserVersion >= BROWSER_WEBGL_VERSIONS.SAFARI);
  }

  public parseQueryParams(): QueryParam {
    const searchArr = this.window.location.search.replace('?', '').split('&');
    return searchArr.reduce((params, current) => {
      const [key, value] = current.split('=');
      params[key] = value;
      return params;
    }, {} as QueryParam);
  }
}
