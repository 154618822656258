import { Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsService, PersistenceService, UserService } from '@core/services';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UiConfigService } from '@core/modules/ui/services/ui-config.service';
import { env } from '@env';
import { AnalyticsEventEnum } from '@app/core/services/analytics/analytics-event.enum';
import { User } from '@app/core/interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  showBookUI$ = new BehaviorSubject(false);
  showLibraryUI$ = new BehaviorSubject(false);
  showSettingsUI$ = new BehaviorSubject(false);
  showGameMap$ = new BehaviorSubject(false);
  dropdownIsShown$ = new BehaviorSubject(false);
  devToolsEnabled = env.devToolsEnabled;
  @Output('toggleDropdown') onToggleDropdown = new EventEmitter<boolean>(false);

  constructor(
    public userService: UserService,
    private uiConfigService: UiConfigService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private persistenceService: PersistenceService,
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const isInBook = e.url.includes('/book/');
        const isInDownloadPage = e.url.includes('/download/play');
        this.showBookUI$.next(isInBook || isInDownloadPage);
        this.showSettingsUI$.next(isInBook);
        this.onToggleDropdown.emit(false);
        // TODO find a better way to find if library is the current route: this will cause an issue on mobile
        if (userService.registrationCompleted) {
          const isInLibrary = e.url === '/';
          this.showLibraryUI$.next(isInLibrary);
          this.showGameMap$.next(isInBook || isInLibrary);
        }
      }
    });
  }

  toggleSettings() {
    this.uiConfigService.toggleSettings();
  }

  toggleUserDropdown(): void {
    this.dropdownIsShown$.next(!this.dropdownIsShown$.value);
    this.onToggleDropdown.emit(this.dropdownIsShown$.value);
  }

  backToLibrary(): void {
    const user: User = this.persistenceService.get('user');

    if (this.router.url.startsWith('/download')) {
      this.analyticsService.track(AnalyticsEventEnum.BACK_TO_LIBRARY_DOWNLOAD, {
        session_number: user?.session_count,
        trigger: 'user',
      });
    }

    this.router.navigate(['/']);
  }

  openDownloadPage(): void {
    const user: User = this.persistenceService.get('user');

    this.analyticsService.track(AnalyticsEventEnum.OPEN_GAME_LOBBY, {
      session_number: user?.session_count,
      trigger: 'user',
    });
    this.router.navigate(['/download/play']);
  }
}
