import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MediaObserver } from '@angular/flex-layout';
import { Router, NavigationEnd } from '@angular/router';
import { AnalyticsService, PersistenceService, UserService } from '@core/services';
import {
  AnalyticsEventEnum,
} from '@core/services/analytics/analytics-event.enum';
import { QUERY_OPTIONS_KEY } from '@core/services/user/user.consts';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BrowserService } from './core/services/browser.service';
import { User } from './core/interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  @HostBinding('class') class = '';

  @HostListener('window:mousemove')
  @HostListener('window:keypress')
  @HostListener('window:scroll')
  @HostListener('window:click')
  refreshUserActivity() {
    this.persistenceService.set('sessionEndTime', new Date(new Date().getTime() + this.THIRTY_MINUTES_IN_MILLISECONDS));
    clearInterval(this.userActivity);
    this.setActivityTimeout();
  }

  appName = 'Bookful'
  userActivity: any;
  userInactive$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();
  readonly THIRTY_MINUTES_IN_MILLISECONDS = 1800000;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private persistenceService: PersistenceService,
    private mediaObserver: MediaObserver,
    private analyticsService: AnalyticsService,
    private router: Router,
    private browserService: BrowserService,
    private window: Window,
  ) {
    titleService.setTitle(this.appName);
    mediaObserver.media$.subscribe((m) => {
      document.body.className = m.mqAlias;
    });
    this.saveQueryOptions();
    this.sessionSetup();
  }

  registerSessionStarted(): void {
    const user: User = this.persistenceService.get('user');
    this.persistenceService.set('sessionStartTime', new Date());
    this.analyticsService.track(AnalyticsEventEnum.START_SESSION, {
      browser: this.window.navigator.userAgent,
      referrer: this.window.document.referrer,
      subscribed: !!user?.purchased,
      session_count: user?.session_count,
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.analyticsService.track(
          AnalyticsEventEnum.PAGE_VIEW,
          event.urlAfterRedirects,
        );
      }
    });
  }

  ngOnInit(): void {
    if (!this.browserService.isBrowserSupported()) {
      this.router.navigate(['/unsupported']);
      return;
    }

    fromEvent<StorageEvent>(this.window, 'storage').pipe(
      filter(event => event.storageArea === localStorage),
      filter(() => !this.persistenceService.get('user')),
      filter(() => !this.persistenceService.get('refresh')),
    )
      .subscribe(() => {
        this.userService.signOutClear();
        this.persistenceService.set('refresh', true);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.analyticsService.track(AnalyticsEventEnum.AFTER_VIEW_CHECKED);
  }

  setActivityTimeout(): void {
    this.userActivity = setInterval(() => {
      this.userInactive$.next();
    }, 10000);
  }

  private sessionSetup(): void {
    const currentTime = new Date().getTime();
    const sessionEndTime = this.persistenceService.get('sessionEndTime');
    if (!this.userService.sessionInitialized || currentTime >= new Date(sessionEndTime)?.getTime()) {
      this.registerSessionStarted();
    }

    this.setActivityTimeout();
    this.persistenceService.set('sessionEndTime', new Date(new Date().getTime() + this.THIRTY_MINUTES_IN_MILLISECONDS));
    this.userInactive$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const currentTime = new Date().getTime();
        const sessionStartTime = new Date(this.persistenceService.get('sessionStartTime')).getTime();
        const sessionEndTime = new Date(this.persistenceService.get('sessionEndTime')).getTime();

        if (currentTime >= sessionEndTime) {
          const duration = `${Math.floor((sessionEndTime - sessionStartTime) / 60000)} minutes`;
          this.analyticsService.track(AnalyticsEventEnum.STOP_SESSION, { duration });
          this.router.navigate(['/']);
          this.registerSessionStarted();
        }
      });
  }

  saveQueryOptions() {
    const params = this.browserService.parseQueryParams();
    this.persistenceService.set(QUERY_OPTIONS_KEY, params);
  }
}
