<h2>Sign Up to Bookful!</h2>
<p class='medium-16'>Creating an account will allow you access across different devices</p>

<form class="sign-up" [formGroup]='form' (keyup.enter)="signUp()">
  <div class="sign-up-form-field">
      <b-input
      id='email'
      label='Grown-up email'
      placeholder='e.g: your@email.com '
      formControlName='email'
      ></b-input>
    <caption id="email-error" *ngIf="email?.errors?.pattern">
      Email is invalid!
    </caption>
  </div>

  <div class="sign-up-form-field">
    <b-input
      id='password'
      label='Password'
      placeholder='Create Password (At least 6 characters)'
      formControlName='password'
      type='password'
    ></b-input>
  </div>

  <div class="sign-up-form-field">
    <b-checkbox
    formControlName='subscribed_to_newsletter'
    label='Yes! Please let me know about new books and activities.'
  ></b-checkbox>
  </div>
</form>
