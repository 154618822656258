import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { env } from '@env';
import { NotFoundComponent } from '@app/not-found/not-found.component';
import { SignUpModule } from '@app/sign-up/sign-up.module';
import { TokenInterceptor } from '@core/services';
import { LayoutModule, UiModule } from '@core/modules';
import { LibraryService } from '@app/library/services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UnsupportedBrowsersComponent } from './unsupported-browsers/unsupported-browsers.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnsupportedBrowsersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UiModule,
    LayoutModule,
    SignUpModule,
  ],
  providers: [
    LibraryService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: env.gtmContainerId },
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent],
  exports: [
  ],
})
export class AppModule { }
