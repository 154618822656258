export const SORT_OPTION_KEY = 'current_sort_option';

export enum SortOptionsCategoryEnum {
  SUBJECTS = 'subjects',
  LEVELS = 'levels'
}

export const librarySortOptions = [
  {
    category: SortOptionsCategoryEnum.SUBJECTS,
    icon: 'subjects.svg',
  },
  {
    category: SortOptionsCategoryEnum.LEVELS,
    icon: 'levels.svg',
  },
];
