export enum UserInterestEnum {
    MAGIC = 'Magic',
    OUR_WORLD = 'Reference',
    ADVENTURE = 'Adventure',
    ANIMALS = 'Animals',
    FRIENDSHIP = 'Friendship',
    MYTHOLOGY = 'Greek Myths',
    FIRST_WORDS = 'First Words',
    REPTILES = 'Reptiles',
    MUSIC = 'Music',
    TALES = 'Tales',
    WILD_ANIMALS = 'Wild Animals',
    OCEAN_CREATURES = 'Ocean Creatures'
}

export type UserInterestOption = { icon: string, enum: UserInterestEnum, copy: string };

export const UserInterestOptions: UserInterestOption[] = [
  {
    icon: 'our-world.svg',
    enum: UserInterestEnum.OUR_WORLD,
    copy: 'Our World',
  },
  {
    icon: 'first-words.svg',
    enum: UserInterestEnum.FIRST_WORDS,
    copy: 'First Words',
  },
  {
    icon: 'friendship.svg',
    enum: UserInterestEnum.FRIENDSHIP,
    copy: 'Friendship',
  },
  {
    icon: 'magic.svg',
    enum: UserInterestEnum.MAGIC,
    copy: 'Magic',
  },
  {
    icon: 'animals.svg',
    enum: UserInterestEnum.ANIMALS,
    copy: 'Animals',
  },
  {
    icon: 'reptiles.svg',
    enum: UserInterestEnum.REPTILES,
    copy: 'Reptiles',
  },
  {
    icon: 'adventure.svg',
    enum: UserInterestEnum.ADVENTURE,
    copy: 'Adventure',
  },
  {
    icon: 'music.svg',
    enum: UserInterestEnum.MUSIC,
    copy: 'Music',
  },
  {
    icon: 'tales.svg',
    enum: UserInterestEnum.TALES,
    copy: 'Tales',
  },
  {
    icon: 'mythology.svg',
    enum: UserInterestEnum.MYTHOLOGY,
    copy: 'Mythology',
  },
  {
    icon: 'wild-animals.svg',
    enum: UserInterestEnum.WILD_ANIMALS,
    copy: 'Wild Animals',
  },
  {
    icon: 'ocean-creatures.svg',
    enum: UserInterestEnum.OCEAN_CREATURES,
    copy: 'Ocean Creatures',
  },
];
