import pkg from '../../package.json';

export const env = {
  version: pkg.version,
  appId: 'bookful-web',
  production: true,
  apiUrl: 'https://development.bookful.inceptionxr.com/',
  stripe: {
    success_url: '/sign-up?purchase=completed&session_id={CHECKOUT_SESSION_ID}',
    cancel_url: '/sign-up?purchase=cancelled&session_id={CHECKOUT_SESSION_ID}',
    prices: {
      // https://dashboard.stripe.com/test/products/prod_K4JK9M8xqIwif6
      annual: 'price_1JQAi1ELSVeIIV89VDszI7LG',
      // https://dashboard.stripe.com/test/products/prod_K4JL8VbDEaNA5T
      lifetime: 'price_1JQAiVELSVeIIV89oHppR1VX',
    },
  },
  mixpanelToken: 'e5c6081e85ab8aa4ad6b6bfb80581c48',
  devToolsEnabled: true,
  gtmContainerId: 'GTM-WRQC5B8',
  webglUrl: 'https://storage.googleapis.com/bookful-dev-webgl-storage',
  googleAnalyticsEnabled: false,
  facebookAnalyticsEnabled: false,
};
