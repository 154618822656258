import { Component, forwardRef, Input, OnInit, Injector, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'b-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true,
  }],
})
export class InputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() id: string;
  @Input() value: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type = 'text';
  @Input() displayName = 'Password';

  control: NgControl | any;
  disabled = false;
  hide = true;
  emailSubscription: Subscription;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.control = this.injector.get(NgControl);
  }

  ngAfterViewInit() {
    if (this.id === 'email') {
      const { control } = this.control;

      this.emailSubscription = control.valueChanges.subscribe((email: string) => {
        if (/^\s+|\s+$/.test(email)) {
          control.setValue(email.trim());
        }
      });
    }
  }

  onChange(e: any) {
    this.value = e;
  }

  onTouched() {
  }

  writeValue(value: any) {
    this.value = value || '';
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) { this.disabled = isDisabled; }

  ngOnDestroy() {
    this.emailSubscription?.unsubscribe();
  }
}
