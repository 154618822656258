<h2>Choose your child’s main interests</h2>

<div class='options'>
  <div
    *ngFor='let val of interests'
    (click)='select(val.enum)'
    [ngClass]='{ selected: selected.includes(val.enum) }'
  >
    <div class='checkbox'>
      <b-icon image='checkmark-green.svg' [transparent]='true'></b-icon>
    </div>

    <img src='assets/img/interests/{{val.icon}}' />
    <p class='medium-16'>{{val.copy}}</p>
  </div>

</div>
