import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseGuard } from '@app/sign-up/services/purchase.guard';
import { LayoutComponent } from '@core/modules/layout/container/layout.component';
import { AuthenticationGuard } from '@app/auth/services/authentication.guard';
import { MainGuard } from '@app/main/services/main.guard';
import { SignInGuard } from '@app/auth/services/sign-in.guard';
import { MobileGuard } from '@core/services/mobile.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnsupportedBrowsersComponent } from './unsupported-browsers/unsupported-browsers.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./library/library.module').then(mod => mod.LibraryModule),
        canActivate: [AuthenticationGuard, PurchaseGuard, MobileGuard],
        data: {
          hideBrands: true,
          name: 'library',
        },
      },
      {
        path: 'download',
        loadChildren: () => import('./main/main.module').then(mod => mod.MainModule),
        canActivate: [AuthenticationGuard, PurchaseGuard],
        data: {
          hideBrands: false,
          name: 'download',
        },
      },
      {
        path: 'sign-up',
        pathMatch: 'full',
        loadChildren: () => import('./sign-up/sign-up.module').then(mod => mod.SignUpModule),
        canActivate: [MainGuard],
        data: {
          hideBrands: false,
          name: 'sign up',
        },
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule),
        canActivate: [SignInGuard, MainGuard],
        data: {
          hideBrands: false,
          name: 'join bookful',
        },
      },
      {
        path: 'unsupported',
        component: UnsupportedBrowsersComponent,
        data: {
          hideBrands: true,
        },
      },
      { path: '**',
        component: NotFoundComponent,
        data: {
          showBrands: true,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
