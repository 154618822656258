<img
  [hidden]='!loaded'
  (load)='onLoaded()'
  [src]='src'
  [alt]='alt'
  [width]='width'
  [height]='height'
/>

<nz-skeleton-element
  [hidden]='loaded'
  [nzActive]='true'
  nzType='image'
  style.width='{{width}}px'
  style.height='{{height}}px'
></nz-skeleton-element>
