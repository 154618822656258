import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '@core/services';

@Injectable({ providedIn: 'root' })
export class PurchaseGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const purchaseJustCompleted = route.queryParamMap.get('purchase');

    if (!this.userService.registrationCompleted || !!purchaseJustCompleted) {
      this.router.navigate(['/sign-up']);
      return false;
    }
    return true;
  }
}
