import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, ViewportScroller } from '@angular/common';
import { AnalyticsService, UserService } from '@core/services';
import { Step, User } from '@core/interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, flatMap, map, tap } from 'rxjs/internal/operators';
import { PurchaseService } from '@app/sign-up/services/purchase/purchase.service';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';
import { UiConfigService } from '@core/modules/ui/services/ui-config.service';
import { SystemEventsService } from '@app/core/services/system-events/system-events.service';
import { EventsActionEnum, EventsCategoryEnum } from '@app/core/services/system-events/system-events.enum';

@Component({
  selector: 'app-sign-up-container',
  templateUrl: './sign-up.container.html',
  styleUrls: ['./sign-up.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpContainer {
  steps: Step[] = [
    {
      id: 'age',
      title: 'Age',
      url: 'Personalize',
      prop: 'age',
      event: AnalyticsEventEnum.SELECTED_AGE,
      action: this.userService.update,
    },
    {
      id: 'interests',
      title: 'Interests',
      url: 'Personalize',
      prop: 'interested_in',
      event: AnalyticsEventEnum.SELECTED_INTERESTS,
      action: this.userService.update,
    },
    {
      id: 'sign-up',
      title: 'Sign Up',
      url: 'SignUp',
      prop: 'email',
      optionalProp: 'account_id',
      event: AnalyticsEventEnum.SIGNED_UP,
      action: this.userService.signUpWithEmail,
    },
    {
      id: 'choose-plan',
      title: 'Choose a Plan',
      url: 'ChoosePlan',
      prop: 'purchased',
      action: this.purchaseService.createCheckoutSession,
    },
  ];

  stepId$ = new BehaviorSubject(this.steps[0].title);
  subPlansData$: Observable<any>;
  stepData: Partial<User> | string = {};
  sessionId: string;

  step$ = new BehaviorSubject(0);
  loading$ = new BehaviorSubject(false);
  checkingPurchase$ = new BehaviorSubject(false);
  valid$ = new BehaviorSubject(false);
  hasInitStep = false;

  constructor(
    public userService: UserService,
    private purchaseService: PurchaseService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private uiConfigService: UiConfigService,
    private systemEventsService: SystemEventsService,
  ) {
    this.setupABTesting();
    this.step$.subscribe((step) => {
      if (!this.hasInitStep) {
        //     Bail if we haven't initialized the step yet, otherwise we will get
        //     double tracking.
        return;
      }
      const pagePath = `/sign-up?step=${this.steps[step].url}&index=${step}`;
      location.go(pagePath);

      // Track page view directly, since the view change is not done through router.
      analyticsService.track(AnalyticsEventEnum.PAGE_VIEW, pagePath);
    });

    this.route.queryParams.subscribe(({ session_id }) => {
      const { data } = this.userService;
      // provided by stripe by starting a checkout session
      this.sessionId = session_id;
      this.hasInitStep = true;
      if (session_id) {
        // show next step so user will not see Choose a Plan for few seconds during purchase check
        this.findAndSetStep(data);
        this.checkingPurchase$.next(true);

        this.purchaseService.syncCheckoutSession(this.sessionId)
          .pipe(
            tap(() => this.analyticsService.track(AnalyticsEventEnum.PURCHASE)),
            flatMap(() => this.userService.get()),
            catchError(e => of(e)),
          )
          .subscribe(res => {
            this.checkingPurchase$.next(false);
            this.findAndSetStep(this.userService.data);
          });
        // user is signed up already but no stripe session
      } else if (data?.email || data?.account_id) {
        this.findAndSetStep(data);
      } else {
        this.setStep(0);
      }
    });

    this.subPlansData$ = this.systemEventsService
      .send(EventsCategoryEnum.CHOOSE_PLAN, EventsActionEnum.OPENED)
      .pipe(
        map(res => res.goods[0]?.goods_data),
      );
  }

  findAndSetStep(data: any) {
    const curStep = this.steps.findIndex((i) => {
      const value = data?.[i.prop as keyof User] || data?.[i.optionalProp as keyof User];
      return !(Array.isArray(value) ? value.length : value);
    });

    this.setStep(curStep);
  }

  setData(data: Partial<User> | string, callNext = false) {
    this.stepData = data;
    this.valid$.next(true);

    if (callNext) {
      this.next();
    }
  }

  setValid(isValid: boolean) {
    this.valid$.next(isValid);
  }

  prev() {
    this.router.navigate(['/sign-up']);
  }

  next() {
    this.valid$.next(false);
    this.loading$.next(true);

    const step = this.step$.getValue();
    const { action, event } = this.steps[step];

    action!(this.stepData)
      .pipe(
        catchError((_, caught) => {
          this.valid$.next(true);
          this.loading$.next(false);

          return caught;
        }),
      )
      .subscribe(() => {
        if (event) this.analyticsService.track(event, this.stepData);

        this.setStep(step + 1);
        this.loading$.next(false);
        this.stepData = {};
        this.viewportScroller.scrollToPosition([0, 0]);
      });
  }

  setStep(step: number) {
    this.step$.next(step);
    this.stepId$.next(this.steps[step].prop!);
  }

  private setupABTesting() {
    const { stepsOrder } = this.uiConfigService.data || {};

    if (stepsOrder?.length) {
      // change sort
      this.steps = this.steps.sort((a, b) => stepsOrder.indexOf(a.id) - stepsOrder.indexOf(b.id));
    }

    // to make sur Complete is the last
    this.steps.push({
      id: 'complete',
      title: 'Start Reading!',
      prop: 'complete',
      url: 'Complete',
      action: this.userService.update,
    });

    // merge age & interests into one step
    const personalizeIndex = this.steps.findIndex(i => ['Age', 'Interests'].includes(i.title));
    this.steps[personalizeIndex].nextHidden = true;
    this.steps[personalizeIndex].title = 'Personalize';
  }
}
