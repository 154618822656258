import { Injectable } from '@angular/core';
import { HttpService, UserService } from '@core/services';
import { EventsActionEnum, EventsCategoryEnum } from '@core/services/system-events/system-events.enum';
import { SystemEventsResponseDto } from '@core/services/system-events/system-events-response.dto';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SystemEventsService {
  constructor(private http: HttpService, private userService: UserService) {}

  send(category: EventsCategoryEnum, action: EventsActionEnum, id?: string, value?: unknown) {
    return this.http.post<SystemEventsResponseDto>('v1/events', { action, category, id, value }, { noMsg: true })
      .pipe(
        tap((result: SystemEventsResponseDto) => this.userService.updateUserState(result.user)),
      );
  }
}
