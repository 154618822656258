<b-steps [steps]='steps' [current]='(step$ | async)!' labelPlacement='vertical'></b-steps>


<ng-container *ngIf="(stepId$ | async) as stepId">
  <div [ngClass]='{ "container": stepId !== "purchased" }' [ngSwitch]='stepId'>
    <i nz-icon nzType='loading' *ngIf='(checkingPurchase$ | async); else content'></i>

    <ng-template #content>
      <app-personalize *ngSwitchCase='"age"' (ageSelected)='setData($event)'></app-personalize>
      <app-interests *ngSwitchCase='"interested_in"' (interestsSelected)='setData($event)'
                     (validChanged)='setValid($event)'></app-interests>
      <app-sign-up *ngSwitchCase='"email"' (credentialsUpdated)='setData($event.data, $event.callNext)'
                   (validChanged)='setValid($event)'></app-sign-up>
      <app-choose-plan *ngSwitchCase='"purchased"' [subPlansData]="(subPlansData$ | async)"
                       (planSelected)='setData($event, true)'></app-choose-plan>
      <app-purchase-complete
        *ngSwitchCase='"complete"'
        [purchaseCompleted]='userService.registrationCompleted'
        (back)='prev()'
      ></app-purchase-complete>

      <ng-container *ngIf='stepId !== "purchased" && stepId !== "complete"'>
        <b-button class='next'
                  [disabled]='!(valid$ | async)'
                  [loading]='(loading$ | async)!'
                  (click)='next()'
        >
          next
        </b-button>
  
        <div class='link'>
          <a routerLink='/auth/login'>I have an account ></a>
        </div>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
