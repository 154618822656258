import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMAIL_VALIDATION_PATTERN } from '@app/core/constants';
import { User } from '@core/interfaces';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  @Output() credentialsUpdated = new EventEmitter<{data: Partial<User>, callNext: boolean}>();
  @Output() validChanged = new EventEmitter<boolean>();
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_VALIDATION_PATTERN)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    subscribed_to_newsletter: new FormControl(false),
  });

  constructor() {
    this.form.valueChanges.subscribe((form) => {
      this.credentialsUpdated.emit({ data: form, callNext: false });
      this.validChanged.emit(this.form.valid);
    });
  }

  public get email(): AbstractControl | null {
    return this.form.get('email');
  }

  public get password(): AbstractControl | null {
    return this.form.get('password');
  }

  public signUp(): void {
    if (this.form.invalid) { return; }
    this.credentialsUpdated.emit({ data: this.form.value, callNext: true });
  }
}
