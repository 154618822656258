import { Injectable } from '@angular/core';
import { HttpService, PersistenceService } from '@core/services';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Library, LibraryResponse } from '@app/library/interfaces/library.interface';
import { map, tap } from 'rxjs/operators';
import { librarySortOptions, SORT_OPTION_KEY } from '@app/library/services/constants';

@Injectable()
export class LibraryService {
  data$: Subject<Library>;
  currentSortOptionIndex$: BehaviorSubject<number>;

  constructor(private http: HttpService, private persistenceService: PersistenceService) {
    this.currentSortOptionIndex$ = new BehaviorSubject(this.persistenceService.get(SORT_OPTION_KEY) || 0);
    this.data$ = new Subject<Library>();
  }

  fetch(): Observable<Library> {
    return this.http.get<LibraryResponse>('v2/library')
      .pipe(
        map(res => res.categories),
      );
  }

  fetchBySortOrder(index: number): Observable<Library> {
    this.setCurrentSortOptionIndex(index);

    return this.http
      .post<LibraryResponse>('v2/library/change-sort', {
        library_sort: this.getCurrentSortOption().category,
      })
      .pipe(
        map(res => res.categories),
        tap(res => this.data$.next(res)),
      );
  }

  getData(res: any): Subscription {
    return this.data$.subscribe(res);
  }

  setCurrentSortOptionIndex(index: number) {
    this.persistenceService.set(SORT_OPTION_KEY, index);
    this.currentSortOptionIndex$.next(index);
  }

  getCurrentSortOption() {
    return librarySortOptions[this.currentSortOptionIndex$.value];
  }

  clearData(): void {
    this.data$.next([]);
  }
}
