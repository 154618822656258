import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UiModule } from '@core/modules';
import { DevUtilsComponent } from '@core/components/dev-utils/dev-utils.component';
import { LibrarySortComponent } from '@core/modules/layout/components/library-sort/library-sort.component';
import { LayoutComponent } from './container/layout.component';
import { HeaderComponent, FooterComponent, PartnersComponent } from './components';
import { UserDropdownComponent } from './components/user-dropdown/user-dropdown.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    PartnersComponent,
    DevUtilsComponent,
    LibrarySortComponent,
    UserDropdownComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    UiModule,
  ],
  providers: [],
})
export class LayoutModule { }
