<h2>Choose your child’s reading level</h2>

<div class='options' fxLayoutGap.xs='20px grid'>
  <b-button
    *ngFor='let val of ages'
    [outlined]="true"
    [selected]='selected === val.enum'
    (click)='select(val.enum)'
  >
    {{val.copy}}
  </b-button>
</div>
