import { FooterLink } from './interfaces/footer-link.interface';

export const URLS = {
  DOWNLOAD_BOOKFUL_GOOGLEPLAY:
    'https://play.google.com/store/apps/details?id=com.inceptionxr.bookful.app.android&gl=GB',
  DOWNLOAD_BOOKFUL_APPSTORE: 'https://apps.apple.com/US/app/id1428323777?mt=8',
  DOWNLOAD_BOOKFUL_AMAZON: 'https://www.amazon.com/Inception-Bookful/dp/B088T4WNVJ',
};

export const BROWSER_WEBGL_VERSIONS = {
  FIREFOX: 67,
  CHROME: 75,
  EDGE: 18,
  SAFARI: 12,
};

export const FOOTER_LINKS: FooterLink[] = [
  { name: 'Terms of Service', url: 'https://bookful.app/terms-and-conditions/' },
  { name: 'Privacy Policy', url: 'https://bookful.app/privacy-policy' },
  { name: 'Contact us', url: 'https://inceptionxr.atlassian.net/servicedesk/customer/portal/1/create/2' },
];

// eslint-disable-next-line max-len
export const EMAIL_VALIDATION_PATTERN = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
