import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AnalyticsService, UserService } from '@core/services';
import { User } from '@core/interfaces';
import { AnalyticsEventEnum } from '@core/services/analytics/analytics-event.enum';
import { tap } from 'rxjs/operators';
import { EventsActionEnum, EventsCategoryEnum } from '@core/services/system-events/system-events.enum';
import { SystemEventsService } from '@core/services/system-events/system-events.service';

@Injectable()
export class SignUpResolver implements Resolve<User> {
  constructor(private user: UserService, private analytics: AnalyticsService,
              private systemEventsService: SystemEventsService) {
  }

  resolve(): Observable<User> {
    if (!this.user.isLoggedIn) {
      return this.user.signUpAnon().pipe(
        tap((user: User) => {
          const copyUser: User = JSON.parse(JSON.stringify(user));
          delete copyUser.access_token;
          this.analytics.track(AnalyticsEventEnum.ANONYMOUS_LOGIN, copyUser);
          this.systemEventsService
            .send(EventsCategoryEnum.SESSION, EventsActionEnum.STARTED)
            .subscribe();
        }),
      );
    }
    return of(this.user.data);
  }
}
