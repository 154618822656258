import { Injectable } from '@angular/core';
import {
  AnalyticsEventEnum,
} from '@core/services/analytics/analytics-event.enum';
import { AnalyticsProvider } from './analytics.provider';
import { FacebookAnalyticsProvider, MixpanelProvider, GoogleTagManagerProvider } from './providers';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  providers: AnalyticsProvider[];

  methods: Record<AnalyticsEventEnum, string> = {
    [AnalyticsEventEnum.NOT_FOUND]: 'track404',
    [AnalyticsEventEnum.RESET_PASSWORD]: 'trackResetPassword',
    [AnalyticsEventEnum.FORGOT_PASSWORD]: 'trackForgotPassword',
    [AnalyticsEventEnum.START_SESSION]: 'trackStartSession',
    [AnalyticsEventEnum.STOP_SESSION]: 'trackStopSession',
    [AnalyticsEventEnum.ANONYMOUS_LOGIN]: 'trackAnonymousLogin',
    [AnalyticsEventEnum.SELECTED_AGE]: 'trackChooseAge',
    [AnalyticsEventEnum.SELECTED_INTERESTS]: 'trackChooseInterests',
    [AnalyticsEventEnum.SIGNED_UP]: 'trackCompleteSignUp',
    [AnalyticsEventEnum.OPEN_CHOOSE_PLAN]: 'trackOpenChoosePlan',
    [AnalyticsEventEnum.CLOSE_CHOOSE_PLAN]: 'trackCloseChoosePlan',
    [AnalyticsEventEnum.CHOOSE_PLAN]: 'trackChoosePlan',
    [AnalyticsEventEnum.SIGNED_IN]: 'trackSignIn',
    [AnalyticsEventEnum.AFTER_VIEW_CHECKED]: 'trackAfterViewChecked',
    [AnalyticsEventEnum.PAGE_VIEW]: 'trackPageView',
    [AnalyticsEventEnum.INITIATE_CHECKOUT]: 'trackInitiateCheckout',
    [AnalyticsEventEnum.REGISTRATION_COMPLETE]: 'trackRegistrationComplete',
    [AnalyticsEventEnum.PURCHASE]: 'trackPurchase',
    [AnalyticsEventEnum.BOOK_SPREAD_CHANGED]: 'trackBookSpreadChanged',
    [AnalyticsEventEnum.BOOK_CLOSED]: 'trackBookClosed',
    [AnalyticsEventEnum.BOOK_OPENED]: 'trackBookOpened',
    [AnalyticsEventEnum.BOOK_FINISHED]: 'trackBookFinished',
    [AnalyticsEventEnum.BOOK_SLIDER_USED]: 'trackSliderUsed',
    [AnalyticsEventEnum.OPEN_LIBRARY]: 'trackOpenLibrary',
    [AnalyticsEventEnum.BOOK_READ_TO_ME]: 'trackBookReadToMe',
    [AnalyticsEventEnum.WHATS_NEXT_POPUP_BOOK]: 'trackWhatsNextOpenBook',
    [AnalyticsEventEnum.WHATS_NEXT_POPUP_BACK]: 'trackWhatsNextBackToLibrary',
    [AnalyticsEventEnum.SORT_ON_LIBRARY]: 'trackSortOnLibrary',
    [AnalyticsEventEnum.BACK_TO_LIBRARY_WEBGL]: 'trackBackToLibraryFromWebGl',
    [AnalyticsEventEnum.BACK_TO_LIBRARY_DOWNLOAD]: 'trackBackToLibraryFromDownload',
    [AnalyticsEventEnum.OPEN_GAME_LOBBY]: 'trackOpenGameLobby',
  };

  constructor(
    private fbProvider: FacebookAnalyticsProvider,
    private mixpanelProvider: MixpanelProvider,
    private gtmProvider: GoogleTagManagerProvider,
  ) {
    this.providers = [fbProvider, mixpanelProvider, gtmProvider];
  }

  track(event: AnalyticsEventEnum, data?: unknown) {
    const methodName = this.methods[event];
    // @ts-ignore TODO fix types?
    this.providers.forEach(p => p[methodName]?.(data));
  }
}
