<section class="unsupported-content-container">
    <div class="unsupported-content">
        <h2 class="unsupported-content-title">Hey there, your browser is not supported.</h2>
        <h5 class="unsupported-content-text">No worries! any of the browsers below will work great.</h5>
        <div class="unsupported-content-image-container">
            <img src="./assets/img/unsupported-browser-content.png" alt="unsupported browser image" />
        </div>
        <div class="unsupported-content-supported-browsers">
            <div class="unsupported-content-supported-browser">
                <img src="./assets/img/browsers/firefox-logo.png" />
                <p>
                    <b>Firefox</b><br />
                    version {{BROWSER_WEBGL_VERSIONS.FIREFOX}} and above
                </p>
            </div>
            <div class="unsupported-content-supported-browser">
                <img src="./assets/img/browsers/chrome-logo.png" />
                <p>
                    <b>Chrome</b><br />
                    version {{BROWSER_WEBGL_VERSIONS.CHROME}} and above
                </p>
            </div>
            <div class="unsupported-content-supported-browser">
                <img src="./assets/img/browsers/edge-logo.png" />
                <p>
                    <b>MS Edge</b><br />
                    version {{BROWSER_WEBGL_VERSIONS.EDGE}} and above
                </p>
            </div>
        </div>
    </div>
</section>