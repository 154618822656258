import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/internal/operators';
import { env } from '@env';
import { AlertService } from '@core/services/alert.service';
import { ErrorCode } from '@core/interfaces';

export interface RequestOptions {
  cache?: number
  headers?: Record<string, string>
  data?: unknown
  customUrl?: boolean
  noMsg?: boolean
  errorCodes?: ErrorCode
}

@Injectable({ providedIn: 'root' })
export class HttpService {
  private composeUrl(path: string, { customUrl }: RequestOptions) {
    return customUrl ? path : env.apiUrl + path;
  }

  constructor(private http: HttpClient, private alertService: AlertService) {
    this.handleError = this.handleError.bind(this);
  }

  public get<K>(url: string, options: RequestOptions = {}): Observable<K> {
    return this.http
      .get(this.composeUrl(url, options), options)
      .pipe(
        switchMap(this.checkStatus),
        catchError((e) => this.handleError(e, options)),
      );
  }

  public post<K>(url: string, body: unknown = {}, options: RequestOptions = {}): Observable<K> {
    return this.http
      .post(this.composeUrl(url, options), body, options)
      .pipe(
        switchMap(this.checkStatus),
        catchError((e) => this.handleError(e, options)),
      );
  }

  public put<K>(url: string, body: unknown = {}, options: RequestOptions = {}): Observable<K> {
    return this.http
      .put(this.composeUrl(url, options), body, options)
      .pipe(
        switchMap(this.checkStatus),
        catchError((e) => this.handleError(e, options)),
      );
  }

  public delete<K>(url: string, options: RequestOptions = {}): Observable<K> {
    return this.http
      .delete(this.composeUrl(url, options), options)
      .pipe(
        switchMap(this.checkStatus),
        catchError((e) => this.handleError(e, options)),
      );
  }

  // switch map response depending on success
  private checkStatus(res: any) {
    return of(res);
  }

  // returns error object from HttpErrorResponse instance
  private handleError({ error }: HttpErrorResponse, { errorCodes, noMsg }: RequestOptions) {
    const errorMessage = errorCodes?.[error.error_code]?.msg || error.message;

    if (!noMsg) {
      this.alertService.error(errorMessage);
    }

    return throwError(error);
  }
}
