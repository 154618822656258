<div
  [ngClass]="{
    'icon': true,
    primary,
    secondary,
    success,
    disabled,
    error,
    transparent,
    clickable: click.observers.length > 0
  }"
  [class]='size'
>
  <img *ngIf="image" src="assets/img/icons/{{image}}">
  <div class="icon--content">
    <ng-content></ng-content>
  </div>
</div>
