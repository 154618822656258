import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonType } from '@core/modules/ui/components/button/button-constants';

@Component({
  selector: 'b-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  constructor() {
    this.click = new EventEmitter();
  }

  @Input() type: string = ButtonType.PRIMARY;
  @Input() disabled = false;
  @Input() image: string;
  @Input() transparent = false;
  @Input() clickable = false;
  @Input() size = 'large';

  @Output('click') click: EventEmitter<unknown>;

  get primary() {
    return this.type === ButtonType.PRIMARY;
  }

  get secondary() {
    return this.type === ButtonType.SECONDARY;
  }

  get success() {
    return this.type === ButtonType.SUCCESS;
  }

  get error() {
    return this.type === ButtonType.ERROR;
  }

  ngOnInit(): void {
  }
}
