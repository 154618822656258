import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class MainGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean {
    const justCompletedPurchase = !!route.queryParamMap.get('purchase');

    if (!justCompletedPurchase && this.userService.registrationCompleted) {
      this.router.navigate(['/']);
      return false;
    } return true;
  }
}
